import { useSelector } from 'react-redux';
import AvailableLoadOfferRejectionReason from 'shared/enums/available-load-offer-rejection-reason';
import AvailableLoadOfferStatus from 'shared/enums/available-load-offer-status';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { LoadSummaryCarrierOffer, LoadSummaryOffer, } from 'shared/models/loads/load-summaries/load-summary-carrier-offer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { OFFER_STATUS } from 'pages/find-loads-ver2/constants';
import { useIsBookUnavailable } from 'pages/find-loads-ver2/hooks/use-is-book-unavailable.hook';
import { OfferType } from 'shared/enums/offer-type.enum';
import { Offer } from 'shared/models/offers/offer.model';
import { CarrierDetail } from 'shared/models/carrier/carrier-detail.model';
import { useStoredCarrierDetail } from 'app/hooks/store/use-stored-user.hook';
import { offerToLoadSummaryOfferMapper } from '@shared/offers/load-summary-offer-mapper';
import { CarrierQualificationStatus } from 'shared/enums/carrier-qualification-status.enum';
import { IsCarrierRestrictedOnLoad } from 'app/util/loads/cap-restricted-function';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';

export interface OfferStatus {
  isOfferOpen: boolean;
  isOfferClosed: boolean;
  isOfferAccepted: boolean;
  isOfferAcceptedByDataScience: boolean;
  isOfferRejected: boolean;
  isOfferRejectedForPrice: boolean;
  isOfferRejectedForCarrierValidation: boolean;
  isOfferIgnored: boolean;
  isOfferCountered: boolean;
  isOfferExpired: boolean;
  isUnableToProcess: boolean;
  isOfferNotConsidered: boolean;
  isOfferSubmissionError: boolean;
  availableLoadOfferStatus: AvailableLoadOfferStatus;
  offerExpirationDate: Date;
  isFinalNegotiation?: boolean;
}

export interface AvailableLoadOfferAction {
  isCapLocked?: boolean;
  isCapRestricted?: boolean;
  canBook?: boolean;
  canOffer?: boolean;
  canMakeNewOffer?: boolean;
  canMakeCounterOffer?: boolean;
}

export interface BookPrice {
  value: number;
  currencyCode: string;
}

export interface OfferState {
  carrierOffer?: LoadSummaryOffer;
  offerStatus?: OfferStatus;
  availableLoadOfferAction?: AvailableLoadOfferAction;
  bookPrice?: BookPrice;
}

export const buildBookPrice = (load: AvailableLoadSummary, offer: LoadSummaryOffer) => {
  const offerStatus = getOfferStatus(offer);

  const useOfferPrice = offerStatus
    && (offerStatus.isOfferAccepted || offerStatus.isOfferCountered)
    && !offerStatus.isOfferExpired;

  const binPrice = load?.binRateCost?.totalCost;

  if (useOfferPrice) {
    return { value: offer.price, currencyCode: offer.currencyCode };
  } else if (binPrice) {
    return { value: binPrice, currencyCode: load?.binRateCost?.currencyCode };
  }
  return null;
};

export const getOfferStatus = (offer: LoadSummaryOffer) => {
  if (!offer) {
    return null;
  }

  const availableLoadOfferStatus = getAvailableLoadOfferStatus(offer.offerStatus);
  const offerExpireDateTime = ensureUtcDateTime(offer.expirationDateTimeUtc);
  const status = (offer.offerStatus ?? '').toUpperCase();

  const isOfferOpen = availableLoadOfferStatus === AvailableLoadOfferStatus.OPEN && offer.offerType === OfferType.Truck;
  const isOfferClosed = availableLoadOfferStatus === AvailableLoadOfferStatus.CLOSED;
  const isOfferAccepted = availableLoadOfferStatus === AvailableLoadOfferStatus.ACCEPTED && offer.offerType === OfferType.Truck;
  const isOfferAcceptedByDataScience = isOfferAccepted && offer.acceptedByDataScience;
  const isOfferCountered = [AvailableLoadOfferStatus.OPEN, AvailableLoadOfferStatus.ACCEPTED, AvailableLoadOfferStatus.COUNTERED, AvailableLoadOfferStatus.PENDING].includes(status as AvailableLoadOfferStatus)
    && offer.offerType === OfferType.Load;
  const isOfferRejected = availableLoadOfferStatus === AvailableLoadOfferStatus.REJECTED;
  const rejectionReason = (offer.rejectionReason ?? '').toUpperCase();
  const isOfferRejectedForPrice = isOfferRejected && rejectionReason === AvailableLoadOfferRejectionReason.PRICE;
  const isOfferRejectedForCarrierValidation = isOfferRejected && rejectionReason === AvailableLoadOfferRejectionReason.CARRIER_VALIDATION;
  const isOfferIgnored = isOfferRejected && rejectionReason === AvailableLoadOfferRejectionReason.IGNORED;
  const isOfferExpired = availableLoadOfferStatus === AvailableLoadOfferStatus.EXPIRED
    || ((isOfferCountered || isOfferAcceptedByDataScience) && offerExpireDateTime?.getTime() < Date.now())
    || isOfferIgnored;
  const isOfferUnableToProcess = status === OFFER_STATUS.UNABLE_TO_PROCESS;
  const isOfferNotConsidered = status === OFFER_STATUS.NOT_CONSIDERED;
  const isOfferSubmissionError = status === OFFER_STATUS.ERROR || status === OFFER_STATUS.MAXIMUM_NUMBER_OF_OFFERS_SUBMITTED;
  const isFinalNegotiation = offer.isFinalNegotiation;

  return {
    isOfferOpen: isOfferOpen,
    isOfferClosed: isOfferClosed,
    isOfferAccepted: isOfferAccepted,
    isOfferAcceptedByDataScience: isOfferAcceptedByDataScience,
    isOfferRejected: isOfferRejected,
    isOfferRejectedForPrice: isOfferRejectedForPrice,
    isOfferRejectedForCarrierValidation: isOfferRejectedForCarrierValidation,
    isOfferIgnored: isOfferIgnored,
    isOfferCountered: isOfferCountered,
    isOfferExpired: isOfferExpired,
    isUnableToProcess: isOfferUnableToProcess,
    isOfferNotConsidered: isOfferNotConsidered,
    isOfferSubmissionError: isOfferSubmissionError,
    availableLoadOfferStatus: availableLoadOfferStatus,
    offerExpirationDate: offerExpireDateTime,
    isFinalNegotiation: isFinalNegotiation
  };
};

export const getAvailableLoadOfferStatus = (offerStatus: string) => {
  let availableLoadOfferStatus: AvailableLoadOfferStatus;
  let status = (offerStatus ?? '').toUpperCase();

  switch (status) {
    case AvailableLoadOfferStatus.COUNTERED:
    case AvailableLoadOfferStatus.OPEN:
    case OFFER_STATUS.UNABLE_TO_PROCESS:
    case OFFER_STATUS.NOT_CONSIDERED:
      availableLoadOfferStatus = AvailableLoadOfferStatus.OPEN;
      break;
    case AvailableLoadOfferStatus.ACCEPTED:
    case AvailableLoadOfferStatus.REJECTED:
    case AvailableLoadOfferStatus.CLOSED:
    case AvailableLoadOfferStatus.EXPIRED:
    case AvailableLoadOfferStatus.PENDING:
      availableLoadOfferStatus = status;
  }
  return availableLoadOfferStatus;
};

export const ensureUtcDateTime = (dateTime: string) => {
  if (dateTime && dateTime.slice(-1) !== 'Z') {
    return new Date(`${dateTime}Z`);
  }
  return dateTime ? new Date(dateTime) : null;
};

export const isLoadOfferable = (load: AvailableLoadSummary, flags: LDFlagSet): boolean => {

  const {
    showMakeOffer,
    showMakeOfferHazmat,
    showMakeOfferStf,
    showMakeOfferFlatbed,
    showMakeOfferTanker,
    showMakeOfferDroptrailer,
    showMakeOfferTeamload,
    showMakeOfferActivitydate,
  } = flags;

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const activityDateDiff = load.activityDateTime?.getTime() - today?.getTime();

  if (load.isHazMat) {
    return showMakeOfferHazmat;
  }
  if (load.isRegulatedByStf) {
    return showMakeOfferStf;
  }
  if (load.equipmentCode === 'F') {
    return showMakeOfferFlatbed;
  }
  if (load.isTankerEndorsementRequired) {
    return showMakeOfferTanker;
  }
  if (load.hasDropTrailer) {
    return showMakeOfferDroptrailer;
  }
  if (load.isTeamFlag) {
    return showMakeOfferTeamload;
  }
  // 14 to 25 days
  if (activityDateDiff >= 1209600000 && activityDateDiff <= 2160000000) {
    return showMakeOfferActivitydate;
  }

  return showMakeOffer;
};

export const getAvailableLoadOfferActions = (
  load: AvailableLoadSummary,
  offerStatus: OfferStatus,
  carrierDetails: CarrierDetail,
  isLoadBookUnavailable: boolean,
  flags: LDFlagSet
): AvailableLoadOfferAction => {

  if (!load) {
    return null;
  }

  const { loadDetailMakeOffer, showMakeCounterOfferWeb } = flags;

  const hasOffer = !!offerStatus;
  const isOfferAccepted = hasOffer && offerStatus.isOfferAccepted;
  const isOfferCountered = hasOffer && offerStatus.isOfferCountered;
  const isOfferExpired = hasOffer && offerStatus.isOfferExpired;
  const isOfferRejectedForPrice = hasOffer && offerStatus.isOfferRejectedForPrice;
  const isOfferRejectedForCarrierValidation = hasOffer && offerStatus.isOfferRejectedForCarrierValidation;
  const isFinalNegotiation = offerStatus?.isFinalNegotiation;
  const isCarrierCapRestricted = carrierDetails?.carrierQualificationStatus == CarrierQualificationStatus.RESTRICTED;
  const isCarrierQualified = carrierDetails?.carrierQualificationStatus != CarrierQualificationStatus.NEW && carrierDetails?.carrierQualificationStatus != CarrierQualificationStatus.PENDING;
  const isCarrierCapLocked = isCarrierCapRestricted || IsCarrierRestrictedOnLoad((load.carrierTier ?? 'None'), carrierDetails?.capCode);
  const hasBinCost = !!load?.binRateCost?.totalCost;

  const canBook = !isLoadBookUnavailable
    && !isCarrierCapLocked
    && isCarrierQualified
    && (((isOfferCountered || isOfferAccepted) && !isOfferExpired)
      || (hasBinCost && !isOfferRejectedForCarrierValidation));

  const isCarrierEligibleToMakeOffer = !isLoadBookUnavailable
    && !isCarrierCapLocked
    && isCarrierQualified
    && !load.isNotOfferable
    && loadDetailMakeOffer
    && (hasBinCost || isLoadOfferable(load, flags));

  const canOffer = isCarrierEligibleToMakeOffer && !hasOffer;

  const canMakeNewOffer = isCarrierEligibleToMakeOffer
    && isOfferRejectedForPrice
    && !isFinalNegotiation;

  const canMakeCounterOffer = showMakeCounterOfferWeb
    && isCarrierEligibleToMakeOffer
    && (isOfferCountered && !isOfferExpired && !isFinalNegotiation);

  return {
    isCapLocked: isCarrierCapLocked,
    isCapRestricted: isCarrierCapRestricted,
    canBook: canBook,
    canOffer: canOffer,
    canMakeNewOffer: canMakeNewOffer,
    canMakeCounterOffer: canMakeCounterOffer,
  };
};

export const useCarrierOffer = (
  load: AvailableLoadSummary,
  isFromOffersPage?: boolean,
) => {
  const isLoadBookUnavailable = useIsBookUnavailable(load?.number);
  const flags = useFlags();
  const { useNcOffers, usePreferredOffer } = flags;

  const findLoadsCarrierOffer: LoadSummaryOffer = useSelector(
    (state: NavCarrierState) => {
      if (!load) { return null; }
      const loadCarrierOffers: LoadSummaryCarrierOffer[] = state?.findLoads?.results?.offers;

      if (loadCarrierOffers?.length > 0) {
        const carrierOffer = loadCarrierOffers.find((x) => x.loadNumber === load.number);
        return usePreferredOffer ? carrierOffer?.preferredOffer : carrierOffer?.latestOffer;
      }
      return null;
    }
  );

  const offersPageFindOffer: LoadSummaryOffer = useSelector(
    (state: NavCarrierState) => {
      if (!load) { return null; }

      if (!(state?.offers?.length > 0)) { return null; }

      const latestOffer = findNewOffer(state.offers, load.number) ?? findLatestOffer(state.offers, load.number);

      return latestOffer ? offerToLoadSummaryOfferMapper(latestOffer) : null;

      function findNewOffer(offers: Offer[], loadNumber: number): Offer | null {
        if (useNcOffers) {
          return offers.find(o => o.loadNumber === loadNumber && !o.bookOfferId);
        } else {
          return offers.find(o => o.loadNumber === loadNumber && !o.offerId);
        }
      }

      function findLatestOffer(offers: Offer[], loadNumber: number): Offer | null {
        const offersOnLoad = offers.filter(c => c.loadNumber === loadNumber);
        if (offersOnLoad.length === 0) { return null; }
        if (useNcOffers) {
          return offersOnLoad.reduce((previousOffer, currentOffer) => previousOffer.enteredDate < currentOffer.enteredDate ? previousOffer : currentOffer);
        } else {
          return offersOnLoad.reduce((previousOffer, currentOffer) => previousOffer.offerId > currentOffer.offerId ? previousOffer : currentOffer);
        }
      }
    }
  );

  const carrierOffer = isFromOffersPage ? offersPageFindOffer : findLoadsCarrierOffer;
  const carrierDetails = useStoredCarrierDetail();

  const bookPrice = buildBookPrice(load, carrierOffer);
  const offerStatus = getOfferStatus(carrierOffer);
  const availableLoadOfferAction = getAvailableLoadOfferActions(load, offerStatus, carrierDetails, isLoadBookUnavailable, flags);

  if (!load?.number) {
    return null;
  }

  return {
    carrierOffer: carrierOffer,
    offerStatus: offerStatus,
    availableLoadOfferAction: availableLoadOfferAction,
    bookPrice: bookPrice,
  };
};

export default useCarrierOffer;
