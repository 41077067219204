import { Popover } from 'shared/components/popover/popover.component';
import { useTranslation } from 'react-i18next';
import { ReloadsData } from 'pages/find-loads-ver2/hooks/use-reloads.hook';

interface ReloadsPopoverProps {
  reloadsData: ReloadsData;
}

export const ReloadsPopover = ({ reloadsData }: ReloadsPopoverProps) => {
  const { t } = useTranslation();

  const showPopover = !!reloadsData && !!reloadsData.reloads?.length &&
    reloadsData.searchRadius != null &&
    !!reloadsData.origin &&
    !!reloadsData.destination &&
    !!reloadsData.requestTime;

  if (!showPopover) {
    return null;
  }
  return (
    <span className="reloads-more-info-popover">
      <Popover
        content={
          t('RELOADS_MORE_INFO_POPOVER', {
            0: reloadsData?.searchRadius,
            1: reloadsData.origin,
            2: reloadsData.destination,
            3: reloadsData.requestTime
          })}
        triggerClassName="btn btn-clear Popover-trigger"
        isNonButtonTrigger={true}
        preferPlace="above"
        popoverClassName="reloads-more-info-popover-body"
        announceToScreenReader="OPEN_POP_OVER_DIALOG"
        trigger={
          <div>
            <img src="/assets/images/info-circle.svg" className="reloads-more-info-popover-icon" />
          </div>
        }
      />
    </span>);
};
