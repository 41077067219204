import { lazy } from 'react';
import { useDispatch } from 'react-redux';
import { useWindowSize } from 'shared/components/azure-map/hooks/use-window-size.hook';
import { AvailableLoadStop } from 'shared/models/loads/stops/available-load-stop.model';
import { LoadMapDataProvider as MapDataProvider } from 'shared/components/azure-map/load-map-data-context/load-map-data-provider';
import { Button } from 'shared/components/elements/elements.components';
import { showModal } from 'shared/components/modal/modal.actions';
import { useTranslation } from 'react-i18next';
import { LOAD_MAP_MODAL } from 'app/globals/constants';
import '../details/load-map/load-map.component.scss';

const LoadMapModal = lazy(() => import('./load-map/load-map-modal.component')
  .then(module => ({ default: module.LoadMapModal })));

interface LoadMapProps {
  loadNumber: number;
  stops?: AvailableLoadStop[];
};

export const LoadMapComponent = ({ loadNumber, stops }: LoadMapProps) => {
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const modalProps = {
    loadNumber,
    minScreenSize: 852,
    nonInteractive: true,
    classList: 'details-pane',
    mapKey: 'find-loads-details-panel-map',
    windowSize
  } as LoadMapProps;

  const showMapModalHandler = () => {
    dispatch(showModal(LOAD_MAP_MODAL, modalProps));
  };

  return (
    <MapDataProvider stops={stops}>
      <Button
        id="load-map-modal-handler"
        track={true}
        btnClear
        className={'load-map-modal-btn without-map'}
        onClick={showMapModalHandler}>
        <span>{t('VIEW_MAP')}</span>
      </Button>
      <LoadMapModal
        loadNumber={loadNumber}
        mapKey={'find-loads-details-modal-map'}
        windowSize={windowSize}
      />
    </MapDataProvider>
  );
};