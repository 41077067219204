import { useTranslation } from 'react-i18next';
import { Banner } from '@chr/eds-react';

export const StfBanner = () => {
  const { t } = useTranslation();
  const STF_LEARN_MORE_LINK = "https://www.fda.gov/food/food-safety-modernization-act-fsma/fsma-final-rule-sanitary-transportation-human-and-animal-food";

  const onClickLearnMore = () => {
    window.open(STF_LEARN_MORE_LINK);
  };

  return (
    <div className='stf-banner' data-testid="stf-banner">
      <Banner title={t('STF_BANNER')}
        severity="warning"
        actionOne={{ buttonText: t('STF_BANNER_LEARN_MORE'), onClick: onClickLearnMore }}
      />
    </div>
  );
}