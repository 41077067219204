import { AuthenticationType, RequestParameters, ResourceType, ServiceOptions } from "azure-maps-control";
import { environment } from "environments/environment";
import { createContext, useEffect, useMemo, useState } from 'react';
import { getMapAsync } from "@api/geography";
import { useOktaAuth } from '@okta/okta-react';

interface LoadMapDataContextType {
  shape: GeoPosition[];
  legs: RouteLeg[];
  options: ServiceOptions;
}

enum WaypointType {
  NotSpecified = 0,
  Building = 1,
  Truck = 2
}

export const LoadMapDataContext = createContext<LoadMapDataContextType>({ shape: [], legs: [], options: {} });

export const LoadMapDataProvider = ({ children, stops = [] }) => {
  const [shape, setShape] = useState(null)
  const oktaAuth = useOktaAuth();
  const legs = useMemo(() => {
    const routeLegs = stops.reduce(
      (result: RouteLeg[], { warehouse }, currentIndex: number) => {

        if (currentIndex === stops.length - 1) {
          return result;
        }

        const address = warehouse?.address;
        const geoPosition = address?.geolocation;
        const nextAddress = stops[currentIndex + 1].warehouse?.address ?? null;
        const nextGeoPosition = nextAddress?.geolocation ?? null;

        if (!geoPosition?.latitude || !geoPosition?.longitude) {
          return result;
        }

        const leg: RouteLeg = {
          startPlace: address,
          endPlace: nextAddress,
          start: {
            geoPosition,
            type: WaypointType.Building
          },
          end: {
            geoPosition: nextGeoPosition,
            type: WaypointType.Building
          },
        };

        return [...result, leg];
      }, []);
    return routeLegs;
  }, [stops]);

  useEffect(() => {
    const fetchData = async (coordinates: GeoPosition[]) => {
      const result = await getMapAsync(coordinates);
      if (result.success) {
        setShape(result.value);
      } else {
        setShape([]);
      }
    }

    const coordinates = legs.map(({ start }: RouteLeg) => start.geoPosition);
    const finalGeoPosition = legs[legs.length - 1]?.end.geoPosition;

    if (coordinates.length && finalGeoPosition) {
      // Add the last waypoint to complete the route.
      const coords = [...coordinates, finalGeoPosition];
      if (coords) {
        fetchData(coords)
          .catch(err => {
            setShape([]);
          });
      }
    }
  }, [legs]);

  const options = useMemo(() => {
    const token = oktaAuth?.oktaAuth?.getAccessToken();
    const apiKey = environment.apiKey;

    return {
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: '__SUBSCRIPTION_KEY__',
      },
      domain: `${apiConfig.geographyAPI}/atlas-proxy`,
      disableTelemetry: true,
      transformRequest: (url: string, resourceType: ResourceType): RequestParameters => {
        const headers: { [key: string]: string } = {
          ApiKey: apiKey,
        };

        // Conditionally add the authorization header
        if (url.includes('atlas-proxy')) {
          headers['Authorization'] = `Bearer ${token}`;
        }

        return {
          url,
          headers
        }
      },
    } as ServiceOptions;
  }, [shape]);

  return (
    <LoadMapDataContext.Provider value={{ shape, legs, options }}>
      {children}
    </LoadMapDataContext.Provider>
  );
}
