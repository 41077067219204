import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { Currency } from 'shared/components/formatters/currency.formatter';
import { OfferStatus } from 'pages/find-loads-ver2/offers/offer-status.component';
import { useTranslation } from 'react-i18next';
import { useTimer } from 'app/hooks/use-timer.hook';
import { AvailableLoadOfferAction, useCarrierOffer } from 'pages/find-loads-ver2/hooks/use-carrier-offer.hook';
import { useIsBookUnavailable } from 'pages/find-loads-ver2/hooks/use-is-book-unavailable.hook';
import { CapLocked } from '@pages/find-loads-ver2/results/cap-restricted/cap-locked.component';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';

export interface FindLoadResultActionsProps {
  load: AvailableLoadSummary;
  sectionName: string;
}

export const getLabelResource = (flags: LDFlagSet, availableLoadOfferAction: AvailableLoadOfferAction, isWebExclusive: boolean) => {
  const { findLoadsMaintenanceBannerWeb } = flags;
  const { canBook, canOffer, canMakeCounterOffer, canMakeNewOffer } = availableLoadOfferAction ?? {};

  if (findLoadsMaintenanceBannerWeb) {
    return 'CONTACT_CHR';
  }
  if (canBook && (canOffer || canMakeNewOffer)) {
    return 'BOOK_OR_OFFER_ACTION';
  }
  if (canBook && canMakeCounterOffer) {
    return 'BOOK_OR_COUNTER_ACTION';
  }
  if (canBook) {
    return 'BOOK';
  }
  if (canOffer) {
    return 'MAKE_OFFER';
  }
  if (canMakeCounterOffer) {
    return 'MAKE_COUNTER_OFFER';
  }
  if (canMakeNewOffer) {
    return 'MAKE_NEW_OFFER';
  }
  if (!isWebExclusive) {
    return 'CONTACT_CHR';
  }
  return undefined;
}

export const LoadActions = ({ load, sectionName }: FindLoadResultActionsProps) => {
  const { t } = useTranslation();
  const flags = useFlags();
  const isLoadBookUnavailable = useIsBookUnavailable(load?.number);
  const offerState = useCarrierOffer(load);
  const { carrierOffer, offerStatus, availableLoadOfferAction, bookPrice } = offerState;

  const timeLeft: string = useTimer({ timerExpirationDate: offerStatus?.offerExpirationDate, timerFormat: { hourLabel: "h", minuteLabel: "m", secondLabel: "s" } });

  const rate = bookPrice?.value;
  const currencyCode = bookPrice?.currencyCode;
  const hasValidRate: boolean = rate > 0 && !!currencyCode;
  const offer = isLoadBookUnavailable ? null : carrierOffer;
  const showTimer = !offerStatus?.isOfferExpired && timeLeft && (offerStatus?.isOfferAcceptedByDataScience || offerStatus?.isOfferCountered);
  const labelResource = getLabelResource(flags, availableLoadOfferAction, load?.isWebExclusive);

  return (
    <div id={`${sectionName}-action_load-${load.number}`} className="action-area" data-testid="action-area">
      {!offer ? <div></div>
        : <div id={`${sectionName}-action_offer-status-${load.number}`} className="js-offer-status" data-testid="offer-status">
          <div className="sr-only">{t('STATUS')}</div>
          <OfferStatus offerState={offerState} loadNumber={load.number} />
        </div>}
      {hasValidRate &&
        <div id={`${sectionName}-action_load-rate-${load.number}`} className="js-load-rate" data-testid="load-rate">
          <div className="sr-only">{t('RATE')}</div>
          <h4>
            <Currency value={rate} currency={currencyCode} />
          </h4>
        </div>
      }
      <div>
        <label className="js-primary-action" aria-label={t('ACTION')} id={`${sectionName}-action_button-${load.number}`} data-testid="action-button">
          <span>
            {showTimer && <span className="time-left" data-testid="time-left"> {timeLeft} </span>}
            {availableLoadOfferAction?.isCapLocked
              ? <CapLocked carrierTier={load.carrierTier} variant={availableLoadOfferAction?.isCapRestricted ? 'restricted' : 'locked'} />
              : t(labelResource)}
          </span>
        </label>
      </div>
    </div>
  );
};

export default LoadActions;
