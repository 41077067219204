import { buildUrl, post } from '@lib/api';
import Result from "@lib/result";

const baseUrl = apiConfig.geographyAPI;

export async function getMapAsync(geoCoordinates: GeoPosition[]): Promise<result<GeoPosition[]>> {
  if (!geoCoordinates || !geoCoordinates.length || geoCoordinates.length < 2) {
    // The API expects two or more pairs of positions in order to calculate routes.
    return Result.Fail("Invalid geoCoordinates. Must have at least two GeoPositions.");
  }

  const result = await post<mapRouteResponse>(
    buildUrl(baseUrl, 'routes'),
    null,
    { geoCoordinates });

  return result.success
    ? Result.Success(result.value.route.shape)
    : Result.Fail(result.error);
}

export default {
  getMapAsync
}