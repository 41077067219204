import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { OfferState } from 'pages/find-loads-ver2/hooks/use-carrier-offer.hook';
import { Button } from 'shared/components/elements/button.component';
import { formatCurrency } from 'shared/components/formatters/currency.formatter';
import { OfferStatus } from 'pages/find-loads-ver2/offers/offer-status.component';
import { ContactPhone } from 'pages/find-loads-ver2/details/button-area/contact-phone.component';
import { CapRestricted } from 'pages/find-loads-ver2/details/cap-restricted/cap-restricted.component';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';

interface Props {
  load: AvailableLoadSummary;
  offerState?: OfferState;
  offerButtonClick: () => void;
  bookButtonClick: () => void;
  capCode?: string;
  openToCoreDateTime: Date;
  openToBaseDateTime: Date;
}

export const ButtonArea = ({ load, offerState, offerButtonClick, bookButtonClick, capCode, openToCoreDateTime, openToBaseDateTime }: Props) => {
  const { findLoadsCarrierValidation, findLoadsMaintenanceBannerWeb } = useFlags();
  const { t } = useTranslation();
  const carrierValidationResult = useSelector(s => s.findLoads?.results?.carrierValidationResults ? s.findLoads.results.carrierValidationResults[load?.number] : null);

  const calculateOfferButtonResource = (newOffer: boolean, counterOffer: boolean) => {
    if (newOffer) {
      return 'MAKE_NEW_OFFER';
    }
    if (counterOffer) {
      return 'MAKE_COUNTER_OFFER';
    }
    return 'MAKE_OFFER';
  };

  const { canMakeNewOffer, canMakeCounterOffer, isCapRestricted, isCapLocked, canBook, canOffer } = offerState?.availableLoadOfferAction ?? {};
  const passedCarrierValidation = findLoadsCarrierValidation ? carrierValidationResult != false : true; //if flag is off, set to true to allow offer/book (validation will be done in api)
  const canAnyOffer = canOffer || canMakeNewOffer || canMakeCounterOffer;
  const showOfferButton = canAnyOffer && passedCarrierValidation && !findLoadsMaintenanceBannerWeb;
  const showBookButton = canBook && passedCarrierValidation && !findLoadsMaintenanceBannerWeb;
  const notBookableOrOfferableOrCapLocked = !(canBook || canAnyOffer || isCapLocked) && offerState?.availableLoadOfferAction;
  const showCarrierQualification = notBookableOrOfferableOrCapLocked && load.isWebExclusive;
  const showCarrierValidation = !passedCarrierValidation && !showCarrierQualification;
  const showContactNumber = (notBookableOrOfferableOrCapLocked && !showCarrierQualification && !showCarrierValidation) || findLoadsMaintenanceBannerWeb;
  const bookPrice = offerState?.bookPrice;

  return (
    <div className='button-area'>
      <OfferStatus offerState={offerState} loadNumber={load.number} showTimer={true} />
      <div className="offer-buttons">
        {showBookButton &&
          <Button
            btnPrimary
            btnBlock
            onClick={bookButtonClick}>
            {t('BOOK')} ({formatCurrency(bookPrice?.value, bookPrice?.currencyCode)})
          </Button>
        }
        {showOfferButton &&
          <Button
            resource={calculateOfferButtonResource(canMakeNewOffer, canMakeCounterOffer)}
            btnDefault
            btnBlock
            onClick={offerButtonClick} />
        }
        {isCapLocked &&
          <CapRestricted
            loadCarrierTier={load?.carrierTier}
            userCarrierTier={capCode}
            variant={isCapRestricted ? 'restricted' : 'locked'}
            openToCoreDateTime={openToCoreDateTime}
            openToBaseDateTime={openToBaseDateTime}
          />
        }
        {showContactNumber &&
          <ContactPhone bookingContactPhoneNumber={load?.bookingContactPhoneNumber} />
        }
        {showCarrierQualification &&
          <div className='carrier-qualification'>
            {t('CARRIER_QUALIFICATION')}
          </div>
        }
        {showCarrierValidation &&
          <div className='carrier-validation'>
            {t('CARRIER_VALIDATION')}
          </div>
        }
      </div>
    </div>
  );
}
