import { useTranslation } from 'react-i18next';
import { ReloadsPopover } from 'shared/reloads/reloads-more-info-popover.component';
import { ReloadsData } from 'pages/find-loads-ver2/hooks/use-reloads.hook';

import './reloads-title.component.scss';

interface TitleProps {
  reloadsData: ReloadsData;
}

export const ReloadsTitle = ({ reloadsData }: TitleProps) => {
  const { t } = useTranslation();

  return (
    <div className="title" role="heading" aria-level={3}>
      <span className="reloads-counter">
        {!!reloadsData?.numberOfReloads &&
          t('RELOADS', { 0: reloadsData?.numberOfReloads })}
      </span>
      <span>
        <ReloadsPopover reloadsData={reloadsData} />
      </span>
    </div>
  );
};
